import { template as template_5fda40e5596541ac88ac9f63bc134c67 } from "@ember/template-compiler";
const FKText = template_5fda40e5596541ac88ac9f63bc134c67(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
