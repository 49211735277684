import { template as template_264a5e856d55484da22e1cb594aaad71 } from "@ember/template-compiler";
const FKControlMenuContainer = template_264a5e856d55484da22e1cb594aaad71(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
