import { template as template_a4f5d24346504b7684f2d594b8fc4bcb } from "@ember/template-compiler";
const FKLabel = template_a4f5d24346504b7684f2d594b8fc4bcb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
